import * as React from "react";
import { graphql } from "gatsby";
import TermsOfServices from "../modules/Terms";

const TermsPage = () => <TermsOfServices />;
export default TermsPage;

// export const Head = () => <title>Stats</title>;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      nodes {
        data
        ns
        language
      }
    }
  }
`;
